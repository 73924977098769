var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"individualReport"},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-modal',{attrs:{"id":"downloadReportBasedOnFont","hide-footer":"","scrollable":"","size":"md"}},[_c('b-form-select',{staticClass:"mb-1 text-center",attrs:{"options":_vm.fontSizes},model:{value:(_vm.selectedFont),callback:function ($$v) {_vm.selectedFont=$$v},expression:"selectedFont"}}),_c('b-button',{attrs:{"disabled":_vm.downloadPdf.disabled,"variant":"primary","size":"sm","block":""},on:{"click":function($event){return _vm.printParticipantReport(true)}}},[_vm._v(_vm._s(_vm.downloadPdf.text))])],1),_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{attrs:{"header":"Participants"}},[_c('b-list-group',[(_vm.participants.length == 0)?_c('b-list-group-item',[_vm._v("No Participants Found!")]):_vm._l((_vm.participants),function(participant,participantIndex){return _c('b-list-group-item',{key:participantIndex,staticClass:"btn text-left mt-2 border border-info align-middle",attrs:{"active":_vm.activeParticipant == participantIndex ? true : false},on:{"click":function($event){return _vm.getParticipantInfo(participant.id, participantIndex)}}},[_c('div',{staticClass:"d-flex flex-column"},[_c('p',{class:_vm.activeParticipant == participantIndex
                        ? 'm-0 p-0 text-white mb-1'
                        : 'm-0 p-0 text-info mb-1',staticStyle:{"font-size":"12px","font-weight":"600"}},[_vm._v(" "+_vm._s(participant.participant.roll_number == null ? "RN" : participant.participant.roll_number)+") "+_vm._s(participant.participant.first_name)+_vm._s(participant.participant.last_name || "")+" ")]),(participant.id == null)?_c('span',{class:_vm.activeParticipant == participantIndex
                        ? 'text-white mt-1'
                        : 'text-danger mt-1',staticStyle:{"font-size":"10px","font-weight":"600"}},[_vm._v("Form Not Submitted!")]):_vm._e()])])})],2)],1)],1)],1),_c('b-col',{attrs:{"md":"9"}},[(_vm.fetchData)?_c('div',{staticClass:"loading"},[_c('load-data')],1):_vm._e(),(_vm.activeParticipant != null)?_c('div',{staticClass:"bg-white p-3 border border-info"},[_c('div',{staticClass:"d-flex justify-content-between"},[(_vm.participants[_vm.activeParticipant].id != null)?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary","size":"sm","disabled":_vm.downloadPdf.disabled},on:{"click":function($event){return _vm.$bvModal.show('downloadReportBasedOnFont')}}},[_vm._v(_vm._s(_vm.downloadPdf.text))]):_vm._e(),(_vm.participants[_vm.activeParticipant].id != null)?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary","size":"sm","disabled":_vm.downloadPdf.disabled},on:{"click":function($event){return _vm.printPdf()}}},[_vm._v(_vm._s(_vm.downloadPdf.print))]):_vm._e()],1),(_vm.participants[_vm.activeParticipant].id == null)?_c('p',{staticClass:"text-danger text-center",staticStyle:{"font-size":"16px","font-weight":"600"}},[_vm._v(" Form not Submitted! ")]):_vm._e(),(
              _vm.activeParticipant != null &&
                _vm.participants[_vm.activeParticipant].id != null
            )?_c('div',{ref:"studentSheet",staticClass:"participantSheet",attrs:{"id":"printIndividualReport"}},[_c('p',{staticClass:"text-center bg-success text-white mb-2 w-100 p-2 rounded"},[_vm._v(" "+_vm._s(_vm.participants[_vm.activeParticipant].participant.first_name)+" "+_vm._s(_vm.participants[_vm.activeParticipant].participant.last_name || "")+" ")]),_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},_vm._l((_vm.questions),function(question,questionIndex){return _c('div',{key:questionIndex,staticClass:"w-50 p-2 mb-2"},[_c('div',{staticClass:"question"},[_vm._v(" "+_vm._s(question.question_no)+". "+_vm._s(question.name)+" "),(_vm.participants[_vm.activeParticipant].id != null)?_c('div',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.answers[_vm.participants[_vm.activeParticipant].id][question.id])+" ")]):_vm._e()])])}),0),_c('div',{staticClass:"table-responsive"},[(_vm.participants[_vm.activeParticipant].id != null)?_c('table',{staticClass:"table table-bordered table-striped mt-2"},[_c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticClass:"align-middle"},[_vm._v("Sr No.")]),_c('th',{staticClass:"align-middle"},[_vm._v("Approver Name")]),_c('th',{staticClass:"align-middle"},[_vm._v("Approver Role")]),_c('th',{staticClass:"align-middle"},[_vm._v("Approving Status")]),_c('th',{staticClass:"align-middle"},[_vm._v(" Approver Comments ")])])]),_c('tbody',_vm._l((_vm.approvers),function(approver,approverIndex){return _c('tr',{key:approverIndex},[(
                        _vm.approvals[_vm.activeApplication] &&
                          _vm.approvals[_vm.activeApplication][approver.id] != null
                      )?_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(approverIndex + 1)+" ")]):_vm._e(),(
                        _vm.approvals[_vm.activeApplication] &&
                          _vm.approvals[_vm.activeApplication][approver.id] != null
                      )?_c('td',{staticClass:"align-middle"},[_c('span',[_vm._v(" "+_vm._s(approver.name)+" ")])]):_vm._e(),(
                        _vm.approvals[_vm.activeApplication] &&
                          _vm.approvals[_vm.activeApplication][approver.id] != null
                      )?_c('td',{staticClass:"align-middle"},[_c('span',[_vm._v(" "+_vm._s(approver.role)+" ")])]):_vm._e(),(
                        _vm.approvals[_vm.activeApplication] &&
                          _vm.approvals[_vm.activeApplication][approver.id] != null
                      )?_c('td',{staticClass:"align-middle"},[(
                          _vm.approvals[_vm.activeApplication] &&
                            _vm.approvals[_vm.activeApplication][approver.id]
                              .approval_status == 'approved'
                        )?_c('span',{class:'text-success'},[_vm._v(" Approved ")]):_vm._e(),(
                          _vm.approvals[_vm.activeApplication] &&
                            _vm.approvals[_vm.activeApplication][approver.id]
                              .approval_status == 'indirect_approved'
                        )?_c('span',{class:'text-success'},[_vm._v(" Indirect approved ")]):_vm._e(),(
                          _vm.approvals[_vm.activeApplication] &&
                            _vm.approvals[_vm.activeApplication][approver.id]
                              .approval_status == 'rejected'
                        )?_c('span',{class:'text-danger'},[_vm._v(" Rejected ")]):_vm._e(),(
                          _vm.approvals[_vm.activeApplication] &&
                            _vm.approvals[_vm.activeApplication][approver.id]
                              .approval_status == 'resubmission'
                        )?_c('span',{class:'text-info'},[_vm._v(" Resubmission ")]):_vm._e()]):_vm._e(),(
                        _vm.approvals[_vm.activeApplication] &&
                          _vm.approvals[_vm.activeApplication][approver.id] != null
                      )?_c('td',{staticClass:"align-middle"},[_c('span',[_vm._v(" "+_vm._s(_vm.approvals[_vm.activeApplication][approver.id].comments)+" ")])]):_vm._e()])}),0)]):_vm._e()])]):_vm._e()]):_vm._e()])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }