<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="approvalDetailedReport p-3" v-else>
      <b-overlay
        :show="loadApproval"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="md"
      >
        <div class="reportAndAttachments mb-3">
          <b-button variant="danger" size="sm" @click="downloadApprovalReport()"
            >Download Reports</b-button
          >
          <b-button
            variant="danger"
            class="ml-3"
            size="sm"
            @click="downloadApprovalAttachments()"
            >Download Attachments</b-button
          >
          <p @click="closeWindow()" class="btn m-0">
            <i class="fas fa-window-close fa-4x text-danger"></i>
          </p>
        </div>
        <b-tabs content-class="mt-3" justified lazy>
          <b-tab title="Student Vs Approvers">
            <StudentVsApprovers
              :participants="participants"
              :approvers="approvers"
              :approvals="approvals"
            />
          </b-tab>
          <b-tab title="Student Vs Questions">
            <StudentVsQuestions
              :participants="participants"
              :questions="questions"
              :answers="answers"
              :onlineregistration="onlineregistration"
            />
          </b-tab>
          <b-tab title="Individual Reports"
            ><IndividualReport
              :participants="participants"
              :questions="questions"
              :answers="answers"
              :approvers="approvers"
              :approvals="approvals"
          /></b-tab>
        </b-tabs>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import StudentVsApprovers from "./StudentVsApprovers.vue";
import StudentVsQuestions from "./StudentVsQuestions.vue";
import IndividualReport from "./IndividualReport.vue";

export default {
  // props: ["detailedReport"],
  async created() {
    await this.getDetailedReport();
    // this.fetchData = false;
  },
  data() {
    return {
      loadApproval: false,
      // detailedReport: {},

      onlineregistration: null,
      approvals: {},
      approvers: [],
      questions: {},
      participants: [],
      answersArray: [], // for data preparation
      answers: {},
    };
  },
  components: {
    StudentVsApprovers,
    StudentVsQuestions,
    IndividualReport,
  },
  methods: {
    async getDetailedReport() {
      this.fetchData = true;
      let approvalRegId = await this.$route.params.approval_id;

      const url =
        this.$store.getters.getAPIKey.surveyApprovals + `/${approvalRegId}/report`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.prepareReportdata(response.data);

          this.$forceUpdate();
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    async prepareReportdata(reportData) {
      let onlinereg = reportData.onlineregistration;
      this.onlineregistration = {
        academic_year: onlinereg.academic_year,
        created_at: onlinereg.created_at,
        end_at: onlinereg.end_at,
        id: onlinereg.id,
        name: onlinereg.name,
        public_id: onlinereg.public_id,
        start_at: onlinereg.start_at,
        summary: onlinereg.summary,
      };

      let questions = reportData.questions;
      let questionsData = {};
      questions.forEach((question) => {
        questionsData[question.id] = {
          name: question.name,
          question_no: question.question_no,
          summary: question.summary,
          id: question.id,
        };
      });
      this.questions = questionsData;

      let participants = reportData.applications;

      this.participants = participants;

      let answersObject = reportData.appsQuestionsAnswer;
      let answersData = {};

      await this.participants.forEach((participant) => {
        if (participant.id != null) {
          let applicationId = participant.id;
          answersData[applicationId] = {};
          for (const questionId in this.questions) {
            answersData[applicationId][questionId] = "";
          }
        }
      });

      for (const questionId in this.questions) {
        for (const application in answersObject) {
          if (
            answersObject[application] &&
            answersObject[application][questionId] != null
          ) {
            answersData[application][questionId] = answersObject[application][questionId];
          }
        }
      }

      this.answers = answersData;

      let approversData = reportData.approvers;
      let approvers = [];
      approversData.forEach((approver) => {
        approvers.push({
          id: approver.id,
          order: approver.order,
          role: approver.role,
          name:
            approver.institution_user.first_name +
            " " +
            approver.institution_user.last_name,
          approve_all_same_order: approver.approve_all_same_order == 1 ? true : false,
        });
      });
      this.approvers = approvers;

      let approvalsData = reportData.appsApprovarsApproval;

      this.approvals = approvalsData;

      this.fetchData = false;
      // this.loadApproval = true;
    },

    async downloadApprovalReport() {
      this.loadApproval = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregistrations/${this.onlineregistration.id}/report/pdfexport`;
      await this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.onlineregistration.name + ".pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.loadApproval = false;
          response;
        })
        .catch((error) => {
          this.loadApproval = false;
          error;
        });
    },
    async downloadApprovalAttachments() {
      this.loadApproval = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregistrations/${this.onlineregistration.id}/onlinereganswers/attachments/zipexport`;
      await this.$axios
        .post(url, { force_download: false }, { responseType: "blob" })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.onlineregistration.name + ".zip");
          document.body.appendChild(link);
          link.click();
          link.remove();

          this.loadApproval = false;
          response;
        })
        .catch((error) => {
          this.$toast.error(
            "Something went wrong with this event, please try again later!",
            {
              position: "top",
              duration: 3000,
            }
          );
          this.loadApproval = false;
          error;
        });
    },
    closeWindow() {
      window.close();
    },
  },
};
</script>
