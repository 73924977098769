<template>
  <div>
    <!-- <p>Participants: {{ participants }}</p> -->
    <div class="studentVsApprovers">
      <h6
        style="font-size: 16px; font-weight: 600; text-align: center"
        class="text-info m-3"
      ></h6>
      <table class="table table-striped table-bordered">
        <thead class="thead-dark">
          <tr
            v-if="approvers.length == 0"
            style="background-color: white; position: sticky; top: 0; z-index: 2"
          >
            <th colspan="100" class="text-center text-danger">
              No Approvers Found!
            </th>
          </tr>

          <tr
            v-else
            style="background-color: white; position: sticky; top: 0; z-index: 2"
          >
            <th class="align-middle">Roll No</th>
            <th class="align-middle">Participants</th>
            <th class="align-middle">Submitted On</th>
            <th class="align-middle">Admission No.</th>
            <th
              v-for="(approver, approverIndex) in approvers"
              :key="approverIndex"
              class="align-middle"
            >
              <span style="font-size: 12px">{{ approver.name }}</span>
              <br />
              {{ approver.role }} - Order {{ approver.order }}
              <span v-if="approver.approve_all_same_order"
                >(Special Privilege)</span
              >
            </th>
          </tr>
        </thead>
        <tbody v-if="approvers.length > 0" class="bg-white">
          <tr
            v-for="(participant, participantIndex) in participants"
            :key="participantIndex"
          >
            <td class="align-middle">
              {{
                participant.participant.roll_number == null
                  ? "RN"
                  : participant.participant.roll_number
              }}
            </td>
            <td>
              {{ participant.participant.first_name }}
              {{ participant.participant.last_name || "" }}<br />
            </td>
            <td class="align-middle">
              {{
                participant.end_at != null
                  ? convertTimeToLocal(participant.end_at).format(
                      "DD/MM/YYYY hh:mm a"
                    )
                  : ""
              }}
            </td>
            <td class="align-middle">
              {{
                participant.participant.admission_no != null
                  ? participant.participant.admission_no
                  : ""
              }}
            </td>
            <td
              v-for="(approver, approverIndex) in approvers"
              :key="approverIndex"
              class="align-middle text-center"
            >
              <span
                v-if="
                  participant.id != null &&
                    approvals[participant.id][approver.id] &&
                    approvals[participant.id][approver.id]['approval_status'] ==
                      'approved'
                "
                class="bg-success p-1 text-white rounded text-capitalize"
                >Approved</span
              >
              <span
                v-if="
                  participant.id != null &&
                    approvals[participant.id][approver.id] &&
                    approvals[participant.id][approver.id]['approval_status'] ==
                      'indirect_approved'
                "
                class="bg-success p-1 text-white rounded text-capitalize"
                >Auto Approved</span
              >

              <span
                v-if="
                  participant.id != null &&
                    approvals[participant.id][approver.id] &&
                    approvals[participant.id][approver.id]['approval_status'] ==
                      'rejected'
                "
                class="bg-danger p-1 text-white rounded text-capitalize"
                >Rejected
              </span>

              <span
                v-if="
                  participant.id != null &&
                    approvals[participant.id][approver.id] &&
                    approvals[participant.id][approver.id]['approval_status'] ==
                      'resubmission'
                "
                class="bg-info p-1 text-white rounded text-capitalize"
                >Resubmission
              </span>

              <!-- <span
                v-if="
                  !approvals[participant.id][approver.id] &&
                    participant.application != null &&
                    participant.application.end_at == null
                "
                class="bg-warning p-1 text-white rounded"
                >Pending</span
              > -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["participants", "approvers", "approvals"],
  methods: {
    convertTimeToLocal(theTime) {
      let localTime = moment(
        moment.utc(theTime, "YYYY-MM-DD HH:mm:ss").toDate()
      );
      return localTime;
    },
  },
};
</script>

<style >
.studentVsApprovers table tbody tr td span {
  font-size: 12px;
}
</style>
