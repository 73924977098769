<template>
  <div>
    <div class="individualReport">
      <b-row>
        <!-- side panel participant list -->
        <b-col md="3">
          <b-modal
            id="downloadReportBasedOnFont"
            hide-footer
            scrollable
            size="md"
          >
            <b-form-select
              class="mb-1 text-center"
              :options="fontSizes"
              v-model="selectedFont"
            ></b-form-select>
            <b-button
              @click="printParticipantReport(true)"
              :disabled="downloadPdf.disabled"
              variant="primary"
              size="sm"
              block
              >{{ downloadPdf.text }}</b-button
            >
          </b-modal>
          <b-card-group deck>
            <b-card header="Participants">
              <b-list-group>
                <b-list-group-item v-if="participants.length == 0"
                  >No Participants Found!</b-list-group-item
                >
                <b-list-group-item
                  class="btn text-left mt-2 border border-info align-middle"
                  v-else
                  v-for="(participant, participantIndex) in participants"
                  :key="participantIndex"
                  :active="activeParticipant == participantIndex ? true : false"
                  @click="getParticipantInfo(participant.id, participantIndex)"
                >
                  <div class="d-flex flex-column">
                    <p
                      :class="
                        activeParticipant == participantIndex
                          ? 'm-0 p-0 text-white mb-1'
                          : 'm-0 p-0 text-info mb-1'
                      "
                      style="font-size: 12px; font-weight: 600"
                    >
                      {{
                        participant.participant.roll_number == null
                          ? "RN"
                          : participant.participant.roll_number
                      }}) {{ participant.participant.first_name
                      }}{{ participant.participant.last_name || "" }}
                    </p>

                    <span
                      :class="
                        activeParticipant == participantIndex
                          ? 'text-white mt-1'
                          : 'text-danger mt-1'
                      "
                      style="font-size: 10px; font-weight: 600"
                      v-if="participant.id == null"
                      >Form Not Submitted!</span
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-card-group>
        </b-col>

        <!-- choosed participant application contrct -->
        <b-col md="9">
          <div class="loading" v-if="fetchData">
            <load-data></load-data>
          </div>

          <div
            v-if="activeParticipant != null"
            class="bg-white p-3 border border-info"
          >
            <div class="d-flex justify-content-between">
              <b-button
                v-if="participants[activeParticipant].id != null"
                variant="primary"
                class="mb-2"
                size="sm"
                :disabled="downloadPdf.disabled"
                @click="$bvModal.show('downloadReportBasedOnFont')"
                >{{ downloadPdf.text }}</b-button
              >
              <b-button
                v-if="participants[activeParticipant].id != null"
                variant="primary"
                class="mb-2"
                size="sm"
                :disabled="downloadPdf.disabled"
                @click="printPdf()"
                >{{ downloadPdf.print }}</b-button
              >
            </div>
            <p
              class="text-danger text-center"
              style="font-size: 16px; font-weight: 600"
              v-if="participants[activeParticipant].id == null"
            >
              Form not Submitted!
            </p>
            <div
              class="participantSheet"
              v-if="
                activeParticipant != null &&
                  participants[activeParticipant].id != null
              "
              ref="studentSheet"
              id="printIndividualReport"
            >
              <p
                class="text-center bg-success text-white mb-2 w-100 p-2 rounded"
              >
                {{ participants[activeParticipant].participant.first_name }}
                {{
                  participants[activeParticipant].participant.last_name || ""
                }}
              </p>
              <div class="d-flex justify-content-between flex-wrap">
                <div
                  v-for="(question, questionIndex) in questions"
                  :key="questionIndex"
                  class="w-50 p-2 mb-2"
                >
                  <div class="question">
                    {{ question.question_no }}. {{ question.name }}
                    <div
                      v-if="participants[activeParticipant].id != null"
                      class="mt-1"
                    >
                      {{
                        answers[participants[activeParticipant].id][question.id]
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- approvals -->
              <div class="table-responsive">
                <table
                  class="table table-bordered table-striped mt-2"
                  v-if="participants[activeParticipant].id != null"
                >
                  <thead class="thead-dark">
                    <tr>
                      <th class="align-middle">Sr No.</th>
                      <th class="align-middle">Approver Name</th>
                      <th class="align-middle">Approver Role</th>
                      <th class="align-middle">Approving Status</th>
                      <th class="align-middle">
                        Approver Comments
                        <!-- <span
                          @click="
                            deleteFormDeprecating(
                              participants[activeParticipant].id
                            )
                          "
                          ><i
                            style="font-size:9px !important;"
                            class="fas fa-trash ml-3"
                          ></i
                        ></span> -->
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(approver, approverIndex) in approvers"
                      :key="approverIndex"
                    >
                      <td
                        class="align-middle"
                        v-if="
                          approvals[activeApplication] &&
                            approvals[activeApplication][approver.id] != null
                        "
                      >
                        {{ approverIndex + 1 }}
                      </td>
                      <td
                        class="align-middle"
                        v-if="
                          approvals[activeApplication] &&
                            approvals[activeApplication][approver.id] != null
                        "
                      >
                        <span>
                          {{ approver.name }}
                        </span>
                      </td>
                      <td
                        class="align-middle"
                        v-if="
                          approvals[activeApplication] &&
                            approvals[activeApplication][approver.id] != null
                        "
                      >
                        <span>
                          {{ approver.role }}
                        </span>
                      </td>
                      <td
                        class="align-middle"
                        v-if="
                          approvals[activeApplication] &&
                            approvals[activeApplication][approver.id] != null
                        "
                      >
                        <span
                          v-if="
                            approvals[activeApplication] &&
                              approvals[activeApplication][approver.id]
                                .approval_status == 'approved'
                          "
                          :class="'text-success'"
                        >
                          Approved
                        </span>

                        <span
                          v-if="
                            approvals[activeApplication] &&
                              approvals[activeApplication][approver.id]
                                .approval_status == 'indirect_approved'
                          "
                          :class="'text-success'"
                        >
                          Indirect approved
                        </span>
                        <span
                          v-if="
                            approvals[activeApplication] &&
                              approvals[activeApplication][approver.id]
                                .approval_status == 'rejected'
                          "
                          :class="'text-danger'"
                        >
                          Rejected
                        </span>
                        <span
                          v-if="
                            approvals[activeApplication] &&
                              approvals[activeApplication][approver.id]
                                .approval_status == 'resubmission'
                          "
                          :class="'text-info'"
                        >
                          Resubmission
                        </span>
                      </td>

                      <td
                        class="align-middle"
                        v-if="
                          approvals[activeApplication] &&
                            approvals[activeApplication][approver.id] != null
                        "
                      >
                        <span>
                          {{
                            approvals[activeApplication][approver.id].comments
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    for (let i = 9; i <= 16; i++) {
      this.fontSizes.push({ value: i, text: i + "px" });
    }
  },
  props: ["participants", "questions", "answers", "approvers", "approvals"],
  data() {
    return {
      activeParticipant: null,
      activeApplication: null,
      fetchData: false,
      downloadPdf: {
        text: "Download",
        print: "Print",
        disabled: false,
      },
      fontSizes: [],
      selectedFont: "9",
    };
  },
  methods: {
    async printPdf() {
      this.downloadPdf = {
        text: "Please wait...",
        print: "Please wait...",
        disabled: true,
      };
      let url;

      url =
        this.$store.getters.getAPIKey.mainAPI +
        `/onlineregapplications/${this.activeApplication}/report/pdfexport`;

      // const downloadedObject = null;

      await this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          // downloadedObject = response.data;
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          window.open(url, "_blank").print();

          this.downloadPdf = {
            text: "Download",
            print: "Print",
            disabled: false,
          };
          response;
        })
        .catch((error) => {
          this.downloadPdf = {
            text: "Download",
            print: "Print",
            disabled: false,
          };
          error;
        });
    },
    async getParticipantInfo(participantId, participantIndex) {
      this.fetchData = await true;
      this.activeParticipant = await participantIndex;
      this.activeApplication = await participantId;

      this.fetchData = await false;
    },
    async printParticipantReport(status) {
      this.downloadPdf = {
        text: "Please wait...",
        print: "Please wait...",
        disabled: true,
      };
      let url;
      if (status) {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/onlineregapplications/${this.activeApplication}/report/pdfexport?fond_size=${this.selectedFont}`;
      } else {
        url =
          this.$store.getters.getAPIKey.mainAPI +
          `/onlineregapplications/${this.activeApplication}/report/pdfexport`;
      }

      // const downloadedObject = null;

      await this.$axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          // downloadedObject = response.data;
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", "participantReport.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloadPdf = {
            text: "Download",
            print: "Print",
            disabled: false,
          };
          response;
        })
        .catch((error) => {
          this.downloadPdf = {
            text: "Download",
            print: "Print",
            disabled: false,
          };
          error;
        });
      // return downloadedObject
    },
    deleteFormDeprecating(appId) {
      let deleteConfirm = prompt(
        "You are going to delete this application. (this function is only intented for tesping/ debuggin purpouse only . please dont use this function)",
        ""
      );
      if (deleteConfirm == "delete") {
        const url =
          this.$store.getters.getAPIKey.mainAPI +
          `/onlineregapplications/${appId}`;
        this.$axios
          .delete(url)
          .then(() => {
            this.$toast.success("Form deleted successfully", {
              position: "top",
              duration: 3000,
            });
          })
          .catch((error) => {
            let errormesage = "Failed to delete";
            if (error.response) errormesage = error.response.data.message;
            this.$toast.error(errormesage, {
              position: "top",
              duration: 3000,
            });
          });
      }
    },
  },
};
</script>

<style >
@media print {
  ::v-deep .right-sidebar,
  ::v-deep header {
    display: none !important;
  }
  ::v-deep footer {
    display: none !important;
  }
}
</style>
