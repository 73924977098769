<template>
  <div>
    <div class="studentVsApprovers">
      <h6
        style="font-size: 16px; font-weight: 600; text-align: center"
        class="text-info m-3"
      >
        {{ onlineregistration.name }}
      </h6>
      <b-button
        class="ml-2 mb-2"
        variant="primary"
        style="font-size: 14px; font-weight: 600"
        size="sm"
        :disabled="exportCsvBtn.disabled"
        @click="exportCourseReportToExcel('xlsx')"
        >{{ exportCsvBtn.text }}</b-button
      >
      <table class="table table-striped table-bordered" ref="studentVsQuestion">
        <thead class="thead-dark">
          <tr v-if="questions.length == 0">
            <th class="text-center text-danger">No Questions Found!</th>
          </tr>
          <tr
            v-else
            style="background-color: white; position: sticky; top: 0; z-index: 2"
          >
            <th class="align-middle">Roll No.</th>
            <th class="align-middle">Participants</th>
            <th class="align-middle">Submitted On</th>
            <th
              v-for="(question, questionIndex) in questions"
              :key="questionIndex"
              class="align-middle"
            >
              {{ question.question_no }}. {{ question.name }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr
            v-for="(student, studentIndex) in participants"
            :key="studentIndex"
          >
            <td class="align-middle">
              {{
                student.participant.roll_number == null
                  ? "RN"
                  : student.participant.roll_number
              }}
            </td>
            <td class="align-middle">
              {{ student.participant.first_name }}
              {{ student.participant.last_name || "" }}
            </td>
            <td class="align-middle">
              {{
                student.end_at != null
                  ? convertTimeToLocal(student.end_at).format(
                      "DD/MM/YYYY hh:mm a"
                    )
                  : ""
              }}
            </td>
            <td
              v-for="(question, questionIndex) in questions"
              :key="questionIndex"
              class="align-middle"
            >
              <span v-if="student.id != null">{{
                answers[student.id][question.id]
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import moment from "moment";
export default {
  props: ["participants", "questions", "answers", "onlineregistration"],
  data() {
    return {
      exportCsvBtn: {
        text: "Export to Excel",
        disabled: false,
      },
    };
  },
  methods: {
    convertTimeToLocal(theTime) {
      let localTime = moment(
        moment.utc(theTime, "YYYY-MM-DD HH:mm:ss").toDate()
      );
      return localTime;
    },
    async exportCourseReportToExcel(type, fn, dl) {
      this.exportCsvBtn.text = "Please wait...";
      this.exportCsvBtn.disabled = true;
      var elt = this.$refs.studentVsQuestion;
      var wb = await XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      this.exportCsvBtn.text = "Export to Excel";
      this.exportCsvBtn.disabled = false;
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(
            wb,
            fn ||
              (this.onlineregistration.name + "." || "studentVsQuestions.") +
                (type || "xlsx")
          );
    },
  },
};
</script>

<style >
.studentVsApprovers table tbody tr td span {
  font-size: 12px;
}
</style>
